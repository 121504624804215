
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLButtonElement | null>(null);

    const registration = Yup.object().shape({
      firstName: Yup.string().required().label("Name"),
      lastName: Yup.string().required().label("Surname"),
      email: Yup.string().min(4).required().email().label("Email"),
      password: Yup.string().required().label("Password"),
      password_confirmation: Yup.string()
        .required()
        .oneOf([Yup.ref("password"), null], "Parolanız Eşleşmeli")
        .label("Password Confirmation"),
    });

    onMounted(() => {
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
    });

    const onSubmitRegister = async (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      // eslint-disable-next-line
      submitButton.value!.disabled = true;

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");


     
      let promise = store.dispatch(Actions.REGISTER, values);

      const [errorName] = Object.keys(store.getters.getErrors);
      const error = store.getters.getErrors[errorName];

      promise.then(({ data }) => {
        console.log(data);
        if (data.success) {
          store.commit(Mutations.SET_AUTH, data);
          let timerInterval
          Swal.fire({
            title: 'Kayıt Başarılı',
            timer: 1000,
            icon: 'success',
            showConfirmButton:0,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading()
              const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft()
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          });
          setTimeout(() => {
            router.push({name: 'Main'});
          }, 1000);
        }
        else{
           Swal.fire({
              text: "Kayıt Başarısız, Bilgilerinizi kontrol ediniz",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Tamam!",
              customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
              },
            });
        }
      })
      .catch(({ response }) => {
        console.log("HATA DA DÖNEN RESPONSE");
        console.log(response);
        Swal.fire({
            text: response,
            icon: "info",
            buttonsStyling: false,
            confirmButtonText: "Tamam!",
            customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
            },
          });
      });

      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
    };
  },
});
